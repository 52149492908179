import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'

function errorToast(error, options) {
    let message = error;
    if (error.response) {
        message = error.response;
        if (error.response.data) {
            message = error.response.data;
            if (error.response.message) {
                message = error.response.data.message;
            }
        }
    }

    let myOptions = {
        showIcon: options ? (options.showIcon ?? 'true') :'true',
        hideProgressBar: options ? (options.hideProgressBar ?? 'false') : 'false',
        type: options ? (options.type ?? 'danger') : 'danger',
        transition: options ? (options.transition ?? 'slide') : 'slide',
        toastBackgroundColor: options ? options.toastBackgroundColor : null
    };

    if (myOptions.toastBackgroundColor == null) 
        delete myOptions.toastBackgroundColor;
    
    createToast(
    {
        title: options ? options.title :  "Error",
        description: message
    },  myOptions);
    
}

function successToast(message, options) {


    let myOptions = {
        showIcon: options ? (options.showIcon ?? 'true') :'true',
        hideProgressBar: options ? (options.hideProgressBar ?? 'false') : 'false',
        type: options ? (options.type ?? 'success') : 'success',
        transition: options ? (options.transition ?? 'slide') : 'slide',
        toastBackgroundColor: options ? options.toastBackgroundColor : null
    };

    if (myOptions.toastBackgroundColor == null) 
        delete myOptions.toastBackgroundColor;

    createToast(
        {
            title: "Completado",
            description: message
        }, myOptions);
    
}


function infoToast(message, options) {

    let myOptions = {
        showIcon: options ? (options.showIcon ?? 'true') :'true',
        hideProgressBar: options ? (options.hideProgressBar ?? 'false') : 'false',
        type: options ? (options.type ?? 'info') : 'info',
        transition: options ? (options.transition ?? 'slide') : 'slide',
        toastBackgroundColor: options ? options.toastBackgroundColor : null
    };

    if (myOptions.toastBackgroundColor == null) 
        delete myOptions.toastBackgroundColor;

    createToast(
        {
            title: options ? options.title : "Mensaje",
            description: message
        }, myOptions);
}

function warningToast(message, options) {

    let myOptions = {
        showIcon: options ? (options.showIcon ?? 'true') :'true',
        hideProgressBar: options ? (options.hideProgressBar ?? 'false') : 'false',
        type: options ? (options.type ?? 'warning') : 'warning',
        transition: options ? (options.transition ?? 'slide') : 'slide',
        toastBackgroundColor: options ? options.toastBackgroundColor : null
    };

    if (myOptions.toastBackgroundColor == null) 
        delete myOptions.toastBackgroundColor;


    createToast(
        {
            title: options ? options.title : "Mensaje",
            description: message
        },  myOptions);
}

export { errorToast, successToast, infoToast, warningToast };